<template>
  <div>
    <!--  -->
    <div v-runshow v-if="info.partOne" class="partOne">
      <div class="title">
        <!-- 产品设计理念 -->
        {{ info.partOne.title }}
      </div>
      <div class="desc">
        {{ info.partOne.sub }}
        <!-- 聚焦业务需求  深度共建慢病管理平台 -->
      </div>
      <div class="tips"></div>
      <!--  -->
      <div class="flex j_c_c">
        <img
          v-runcentershow
          class="is_img"
          :src="info.partOne.img"
          v-if="info.partOne.img"
          alt=""
        />
      </div>
      <div
        :class="{ has_img: info.partOne.img }"
        class="flex a_i_c j_c_b ctx pc_"
      >
        <div
          class="item_"
          v-for="(item, index) in info.partOne.swiper"
          :key="index"
        >
          <img class="bg" :src="item.bg" alt="" />
          <div class="pop_ctx" :class="{ mark: item.showBgMark }">
            <div
              style="width: 0.8rem; margin-bottom: 0.1rem"
              v-html="item.icon"
            ></div>
            {{ item.name }}
            <div class="down_line"></div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- mobile -->
      <div :class="{ has_img: info.partOne.img }" class="mobile_">
        <div class="block">
          <div class="sc_swiper swiper" style="height: calc(40vw + 50px)">
            <div class="swiper-wrapper" style="">
              <div
                class="swiper-slide"
                style="width: 100vw"
                v-for="(item, index) in info.partOne.swiper"
                :key="index"
              >
                <div class="item_">
                  <img class="bg" :src="item.bg" alt="" />
                  <div class="pop_ctx" :class="{ mark: item.showBgMark }">
                    <div
                      style="width: 0.8rem; margin-bottom: 0.1rem"
                      v-html="item.icon"
                    ></div>
                    {{ item.name }}
                    <div class="down_line"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="sc_swiper-pagination swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      class="part_two"
      :style="{ backgroundImage: `url('${info.partTwo.bg}')` }"
      :class="{ bg: info.partTwo.bg }"
      v-runshow
    >
      <!-- 轮播图顶部介绍 -->
      <div class="top_info" :class="{ noimac: info.partTwo.right_img }">
        <div class="title">
          <!-- 产品设计理念 -->
          {{ info.partTwo.title }}
        </div>
        <div class="desc">
          {{ info.partTwo.sub }}
          <!-- 聚焦业务需求  深度共建慢病管理平台 -->
        </div>
        <div class="tips"></div>
      </div>

      <div
        :class="{ mobile_type1_center: info.partTwo.right_img }"
        class="content p_r"
      >
        <!-- 轮播图左侧 -->
        <div
          class="left_content"
          style="padding: 40px; box-sizing: border-box; color: #000"
        >
          <div class="content_title">
            {{ partTwoList[current_partTwo_index].title }}
          </div>
          <div class="content_desc">
            {{ partTwoList[current_partTwo_index].desc }}
          </div>
          <!-- <div class="flex a_i_c change">
            <div
              @click="onChange(-1)"
              :class="{ can_: current_partTwo_index > 0 }"
            >
              <svg
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4854 1L2.00007 9.48528L10.4854 17.9706"
                  stroke="#C4C4C4"
                  stroke-width="2"
                />
              </svg>
            </div>
            <div
              @click="onChange(1)"
              :class="{ can_: current_partTwo_index < partTwoList.length - 1 }"
            >
              <svg
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.48535 17.9688L9.97063 9.48347L1.48535 0.998187"
                  stroke="#C4C4C4"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div> -->
          <!-- <div class="number">
            <span style="color: #1aa858">{{ current_partTwo_index + 1 }}</span
            >/{{ partTwoList.length }}
          </div> -->
        </div>
        <!-- 轮播图右侧 -->
        <div class="imgView">
          <img
            class="view"
            v-if="info.partTwo.right_img"
            :src="info.partTwo.right_img"
            alt=""
          />
          <img
            v-if="!info.partTwo.right_img"
            class="view"
            :src="require('@/assets/SmartChronicDisease/view.png')"
            alt=""
          />
          <!-- <img
            class="imac"
            :src="require('@/assets/SmartChronicDisease/iMac.png')"
            alt=""
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {
      current_partTwo_index: 0,
      partTwoList: [
        {
          title: "便捷、批量化的慢病数据上报",
          desc: "清晰、人性化的数据可视，便捷、批量的上传慢病数据，大大提升医生和护理人员的工作效率。",
        },
        // {
        //     title:'222便捷、批量化的慢病数据上报',
        //     desc:'222清晰、人性化的数据可视，便捷、批量的上传慢病数据，大大提升医生和护理人员的工作效率。',
        // },
        // {
        //     title:'3333便捷、批量化的慢病数据上报',
        //     desc:'333清晰、人性化的数据可视，便捷、批量的上传慢病数据，大大提升医生和护理人员的工作效率。',
        // }
      ],
    };
  },
  watch: {
    info() {
      this.partTwoList = this.info.partTwo.list;
    },
  },
  mounted() {
    this.partTwoList = this.info.partTwo.list;
    var mySwiper = new Swiper(".sc_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".sc_swiper-pagination",
      },
    });
  },
  methods: {
    onChange(number) {
      if (number > 0) {
        if (this.current_partTwo_index < this.partTwoList.length - 1) {
          this.current_partTwo_index++;
        }
      } else {
        if (this.current_partTwo_index > 0) {
          this.current_partTwo_index--;
        }
      }
    },
  },
};
</script>

<style scoped>
.bg {
}
.is_img {
  width: 90%;
  max-width: 1000px;
  margin: 0.24rem 0;
  object-fit: contain;
}
.has_img {
  display: none;
  height: 0px;
  overflow: hidden;
}
.box >>> .sc_swiper-pagination-bullet {
  background: #1aa858;
}
.can_ {
  background: #1aa858 !important;
}
.can_ path {
  stroke: #fff !important;
}
.title {
  font-weight: 900;
  font-size: 16px;
  line-height: 42px;
  color: #61646b;
  text-align: center;
  margin-top: 1.2rem;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.mobile_ {
  height: 0px;
  overflow: hidden;
  padding-top: 30px;
}
.desc {
  text-align: center;
  font-size: 0.38rem;
  line-height: 0.53rem;
  color: #21252e;
  margin-top: 0.16rem;
}
.tips {
  width: 0.74rem;
  height: 3px;
  background: #1aa858;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.25rem;
}
.ctx {
  padding: 0.63rem 1.66rem;
}
.item_ {
  position: relative;
  width: 7.52rem;
  height: 4rem;
  background: rgb(94, 94, 94);
}
.mobile_ .item_ {
  position: relative;
  width: 90%;
  margin-left: 5%;
  height: 40vw;
  background: rgb(94, 94, 94);
}
.item_ img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg {
  position: relative;
  z-index: 1;
}
.pop_ctx {
  color: #fff;
  font-size: 0.26rem;
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mark {
  background-color: rgba(0, 0, 0, 0.52);
}
.item_:nth-child(1) {
  margin-right: 40px;
}
.down_line {
  width: 0.5rem;
  height: 2px;
  background: #fff;
  margin-top: 10px;
}
.pop_ctx svg {
  margin-bottom: 6px;
}
/*  */
.part_two {
  background: #373948;
  color: #fff;
  padding: 0 1.9rem;
  padding-bottom: 1.2rem;
}
.part_two .desc {
  color: #fff;
  font-size: 0.38rem;
  line-height: 0.53rem;
}
.part_two .title {
  color: #80838a;
  padding-top: 1.2rem;
  margin-top: 0;
}
.content {
  user-select: none;
  background: #fff;
  /* min-height: 4.74rem; */
  margin-top: 1.26rem;
  display: flex;
  justify-content: space-between;
}
.imac {
  position: absolute;
  bottom: 10px;
  width: 400px;
  right: 0.8rem;
  height: 300px;
  object-fit: contain;
  z-index: 3;
  width: 6.47rem;
  height: 5.38rem;
}
.imgView {
  width: 50%;
  padding: 40px;
  box-sizing: border-box;
}
.view {
  width: 100%;
  z-index: 4;
  max-height: 350px;
  -o-object-fit: contain;
  object-fit: contain;
}
.left_content {
  width: 50%;
}
.content_title {
  font-size: 18px;
  font-weight: 800;
  margin: 20px 0;
  color: #000;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.content_desc {
  font-size: 12px;
  color: #777;
  line-height: 180%;
  margin-bottom: 20px;
  font-size: 0.22rem;
  line-height: 0.36prem;
}
.change > div {
  cursor: pointer;
  width: 0.64rem;
  height: 0.64rem;
  margin-right: 14px;
  background: #f7f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.change svg {
  cursor: pointer;
  width: 10px !important;
  height: 10px !important;
}
.type_1 {
  /* height: 258px !important; */
  height: 4rem;
  bottom: 0.4rem;
}

@media screen and (max-width: 1024px) {
  .mobile_ {
    height: auto;
    overflow: visible;
  }
  .left_content {
    max-width: 100vw;
    width: calc(100vw - 40px);
  }
  .pc_ {
    display: none;
  }
  .content {
    padding-top: 180px;
    width: calc(100vw - 40px);
    margin-left: 20px;
    display: block;
  }
  .imgView {
    position: absolute;
    top: 18px;
    width: 100%;
    padding: 10px 20px;
    height: 166px;
    left: 50%;
    transform: translateX(-50%);
  }
  .view {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .top_info {
    margin-bottom: 120px;
  }

  .imac {
    top: -80px;
    width: auto;
    height: 260px;
    left: 50%;
    transform: translateX(-50%);
  }
  .is_img {
    height: auto !important;
    max-height: 50vw;
  }
  .type_1 {
    top: -70px !important;
    width: 80vw !important;
  }
  .mobile_type1_center {
    padding-top: 140px;
  }
  .mobile_ {
    margin-bottom: 0.7rem;
  }
  .noimac {
    margin-bottom: 70px;
  }

  .part_two {
    padding-left: 0;
    padding-right: 0;
  }
  .part_two .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    color: #ffffff;
  }
  .part_two .desc {
    font-size: 0.38rem;
    line-height: 0.67rem;
    text-align: center;
    color: #ffffff;
    width: 6.42rem;
    margin-left: 0.54rem;
  }
  .content_title {
    font-size: 0.38rem;
    line-height: 0.53rem;
    color: #000000;
  }
  .content_desc {
    font-size: 0.3rem;
    line-height: 0.54rem;
    color: #61646b;
  }
  .number {
    text-align: right;
    margin-top: -0.9rem;
  }
  .partOne .title {
    font-weight: 900;
    font-size: 0.48rem;
    line-height: 0.67rem;
    color: #61646b;
  }
  .partOne .desc {
    padding: 0 20px;
    box-sizing: border-box;
  }
}

/* @media screen and (min-width:1910px) {
    .partOne .title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 42px;
        margin-bottom: 20px;
        margin-top: 80px;
    }
    .part_two .title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 42px;
        color: #80838A;
    }
    .desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
    }
    .part_two{
        padding: 10px 160px;
        padding-bottom: 1.2rem;
    }

    .part_two .desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .content_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 42px;
        padding-left: 40px;
    }
    .content_desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        padding-left: 40px;
    }
    .type_1{
        width: 48%!important;
        height: 360px!important;
        object-fit: cover;
        margin-bottom: 50px;
    }
    .change{
        margin-top: 80px;
        padding-left: 40px;
    }
    .number{
        padding-left: 40px;
        margin-bottom: 20px;
    }
    .change>div{
        width: 64px;
        height: 64px;
        background: #F7F7FA;
    }
} */
</style>
