<template>
  <div>
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '智慧慢病管理平台',
          desc: '以“医防融合”为着力点，依托互联网、物联网、人工智能等技术，为慢病患者提供全方位、多层次、定制化的全程精细化服务，打造慢病管理全周期体系。',
        }"
        :img="require('@/assets/SmartChronicDisease/banner.png')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <isTab :info="info" current="智慧慢病管理平台" />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import isTab from "@/components/ProductTab/tab.vue";
export default {
  name: "SmartChronicDisease",
  data() {
    return {
      info: {
        partOne: {
          title: "产品设计理念",
          sub: "聚焦业务需求  深度共建慢病管理平台",
          swiper: [
            {
              bg: require("@/assets/SmartChronicDisease/01.png"),
              showBgMark:true,
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 90 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1379_32915)" filter="url(#filter0_d_1379_32915)">
                            <path d="M84.6216 41.3453C84.6216 77.8663 44.6552 92.3369 44.6552 92.3369C44.6552 92.3369 5.37793 79.2445 5.37793 41.3453V13.7823L45.3443 1.37891L84.6216 13.7823V41.3453Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M35.0083 42.7207H54.9915" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M44.6582 33.0742V53.0574" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M18.4678 46.8555C20.535 59.9479 31.5602 69.595 45.3417 69.595C59.1232 69.595 70.1484 59.9479 72.2157 46.8555" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M71.5266 39.2762C69.4594 26.1838 58.4342 15.8477 44.6526 15.8477C30.8711 15.8477 20.535 26.1838 18.4678 39.2762" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M75.6654 35.1406L71.5309 39.2751L67.3965 35.1406" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M14.3359 50.3005L18.4704 46.166L22.6048 50.3005" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </g>
                            <defs>
                            <filter id="filter0_d_1379_32915" x="0" y="0" width="90" height="101.715" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1379_32915"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1379_32915" result="shape"/>
                            </filter>
                            <clipPath id="clip0_1379_32915">
                            <rect width="82" height="93.7143" fill="white" transform="translate(4)"/>
                            </clipPath>
                            </defs>
                            </svg>

                            `,
              name: "医防融合",
            },
            {
              bg: require("@/assets/SmartChronicDisease/02.png"),
              showBgMark:true,
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 90 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M86.5029 45.7402H50.7109V76.0639H86.5029V45.7402Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M58.6631 42.2598V49.2193" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M78.0518 42.2598V49.2193" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M50.7109 55.1855H86.5029" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M56.6768 62.1445H59.6594" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M63.6357 62.1445H66.6184" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M70.5957 62.1445H73.5784" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M77.5547 62.1445H80.5373" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M56.6768 68.1094H59.6594" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M63.6357 68.1094H66.6184" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M70.5957 68.1094H73.5784" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M77.5547 68.1094H80.5373" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M1.99414 37.2889H17.4045L23.3699 21.3814L31.8207 46.734L36.7918 29.8323L38.2832 37.2889H68.1098H72.0866C72.0866 17.9017 56.1791 1.99414 36.7918 1.99414C17.4045 1.99414 1.99414 17.4046 1.99414 37.2889ZM1.99414 37.2889C1.99414 57.1733 17.9017 72.5838 37.2889 72.5838C41.7629 72.5838 46.734 71.5895 50.7109 70.0982" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </svg>
                            `,
              name: "全周期体系",
            },
          ],
        },
        partTwo: {
          title: "产品功能介绍",
          sub: "借助华信互联慢病管理管理平台赋能全周期慢病管理体系",
          list: [
            {
              title: "便捷、批量化的慢病数据上报",
              desc: "清晰、人性化的数据可视，便捷、批量的上传慢病数据，大大提升医生和护理人员的工作效率。",
            },
          ],
          right_img: require("@/assets/SmartChronicDisease/dataUp.png"),
        },
      },
    };
  },
  components: {
    commonTop,
    isHeader,
    isFooter,
    isTab,
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
};
</script>

<style>
</style>