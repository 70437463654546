<template>
  <div>
    <div v-if="!hide_tab" class="flex j_c_b a_i_c box">
      <div
        @click="jump(item)"
        class="item p_r"
        :class="{ active: current == item.name }"
        v-for="(item, index) in list"
        :key="index"
      >
        <div>
          {{ item.name }}
        </div>
      </div>
    </div>
    <!--  -->
    <isTab01 :info="info" />
  </div>
</template>

<script>
import isTab01 from "./tab_01.vue";
export default {
  props: ["info", "current", "hide_tab"],
  components: {
    isTab01,
  },
  data() {
    return {
      list: [
        {
          name: "智慧慢病管理平台",
          path: "SmartChronicDisease",
        },
        {
          name: "智慧体检系统",
          path: "IntelligentPhysical",
        },
        {
          name: "智慧随访管理平台",
          path: "SmartFollowup",
        },
        {
          name: "全程满意度调查",
          path: "SatisfactionSurvey",
        },
        // {
        //   name: "慢病管理服务",
        //   path: "ChronicDisease",
        // },
        {
          name: "智慧宣教管理平台",
          path: "SmartEducation",
        },
        {
          name: "智能监测设备",
          path: "IntelligentMonitoring",
        },
        {
          name: "智慧家医工作站",
          path: "SmartFamilyDoc",
        },
        {
          name: "智慧慢病医患小程序",
          path: "SmartWeapp",
        },
        {
          name: "智慧决策驾驶舱",
          path: "SmartDecision",
        },
        {
          name: "智慧导检系统",
          path: "SmartInspection",
        },
        {
          name: "智慧疾病知识库",
          path: "SmartLibrary",
        },
      ],
    };
  },
  methods: {
    jump(item) {
      if (!item.path) {
        alert("开发中 ");
        return;
      }
      this.$router.push({
        path: "/" + item.path,
      });
    },
  },
};
</script>

<style scoped>
.item {
  padding: 0.27rem 0.18rem;
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #666;
  cursor: pointer;
}
.box {
  width: 100%;
  background: #f7f7fa;
}
.active {
  background: #fff;
}
.active::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #1aa858;
  bottom: 0;
  left: 0;
  position: absolute;
}

@media screen and (max-width: 1024px) {
  .box {
    display: none;
  }
}
</style>
