<template>
  <!-- 公共头部栏目 -->
  <div style="overflow: hidden" class="p_r">
    <div v-runbannershow class="p_a pop">
      <h1 class="title">
        {{ info.title }}
      </h1>
      <!-- v-if="info.show_desc" -->
      <p v-if="info.show_desc" class="desc">{{ info.desc }}</p>
      <div class="flex desc">
        <slot></slot>
      </div>
    </div>
    <img v-runbannershow class="img p_r" :src="img" alt="" />
  </div>
</template>

<script>
export default {
  props: ["img", "info"],
};
</script>

<style scoped>
.img {
  z-index: 1;
  margin-left: 24%;
  width: 76vw;
  vertical-align: top;
  max-height: 30vw;
  min-height: 30vw;
  background: rgb(74, 74, 74);
  object-fit: cover;
}
.desc {
  font-size: 0.22rem;
  line-height: 0.31rem;
  margin-top: 0.24rem;
}
.title {
  font-size: 0.52rem;
  line-height: 0.73rem;
  /* margin-top: 0.7rem; */
}
.pop {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 6%;
  padding-right: 6%;
  box-sizing: border-box;
  width: 42%;
  height: 100%;
  color: #fff;
  background: linear-gradient(
    89.09deg,
    #15151f 60.83%,
    rgba(21, 21, 31, 0) 99.27%
  );
}
.pop >>> button {
  background: transparent;
  border: 1px solid #ddd;
  padding: 10px 30px;
  margin-right: 20px;
  margin-top: 20px;
  font-size: 12px;
  cursor: pointer;
  color: #fff;
  font-size: 0.18rem;
  line-height: 0.25rem;
  margin-top: 0.3rem;
}
@media screen and (max-width: 1024px) {
  .pop {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* padding-top: 70px; */
  }
  .img {
    width: 100%;
    margin-left: 0;
    min-height: 5.6rem;
    object-fit: cover;
  }
  .pop >>> button {
    background: transparent;
    border: 1px solid #ddd;
    padding: 0.12rem 0.3rem;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
    color: #fff;
    font-size: 0.36rem !important;
    line-height: 0.5rem;
  }
  .title {
    margin-top: 0.8rem;
    font-size: 0.5rem;
    line-height: 0.7rem;
  }
  .desc {
    font-size: 0.28rem;
    line-height: 0.39rem;
  }
}
@media screen and (min-width: 1910px) {
  .pop {
    width: 42%;
  }
  .title {
    font-size: 52px;
    margin-bottom: 16px;
  }
  .desc {
    font-size: 22px;
    line-height: 31px;
  }
  .pop {
    padding-left: 160px;
    font-size: 0.36rem;
    line-height: 0.5rem;
  }

  .pop >>> button {
    padding: 16px 44px;
    font-size: 18px;
  }
}
</style>